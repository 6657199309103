////////// common classes //////////

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
  background-repeat: no-repeat;
}

html {
  font-size: 12px;
}

ul {
  list-style: none;
}

picture {
  img {
    width: 100%;
    height: auto;
  }
}

p {
  font-size: 1.5rem;
}

code {
  background-color: #666;
  border: 1px solid #4D4D4D;
  padding: .5rem;
  color: white;
  border-radius: .5rem;
  display: block;
  margin: 1rem 0 .5rem;
}

p > code {
  display: inline-block;
  font-size: 1rem;
  color: #333;
  background-color: #EEE;
  border: 1px solid #CCC;
  padding: 0 .2rem;
  border-radius: .2rem;
  margin: 0 .5rem;
}

.button {
  background-color: $color-base-orange;
  border-radius: .4rem;
  border: none;
  padding: .4rem;
  color: white;
  font-weight: bold;
  line-height: 100%;
  display: inline-block;
  transition-property: background-color, transform, box-shadow;
  transition-duration: .2s;
  white-space: nowrap;
  &:hover {
    background-color: #f93;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-decoration: none;
  }
  &.button-s {
    font-size: 1rem;
    font-weight: 300;
    padding: .5rem;
    border-radius: .3rem;
  }
  &.button-l {
    font-size: 1.9rem;
    padding: 1rem 3rem;
    border-radius: 1rem;
  }
}

.button-disabled {
  background-color: #CCC;
  border-radius: .4rem;
  border: none;
  padding: .4rem;
  color: white;
  font-weight: normal;
  line-height: 100%;
  display: inline-block;
  transition-property: background-color, transform, box-shadow;
  transition-duration: .2s;
  white-space: nowrap;
  &.button-s {
    font-size: 1rem;
    font-weight: 300;
    padding: .5rem;
    border-radius: .3rem;
  }
  &.button-l {
    font-size: 1.9rem;
    padding: 1rem 3rem;
    border-radius: 1rem;
  }
}

.wrapper {
  max-width: calc(#{$max-width} + 4rem);
  margin: 0 auto;
  padding: 0 2rem;
}

@media screen and (max-width: 768px){

  .button {
    &.button-l {
      font-size: 1.5rem;
      border-radius: .5rem;
      padding: .8rem 2rem;
    }
  }

}

@media screen and (max-width: 600px){
  
  .button {
    
    &.button-l {
      padding: 1rem 2rem;
      font-size: 1.5rem;
    }
  }
  
}