footer {
  background-color: #F5F5F5;
  border-top: 1px solid #DBDBDB;
  padding: 0 2rem;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 2.4rem 0;
  }
  nav.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DBDBDB;
    p {
      width: auto;
      height: 1.5rem;
      picture {
        display: block;
        height: 16px;
        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      li {
        margin-left: 3rem;
      }
    }
  }
  div.wrapper {
    p {
      color: #666;
      font-size: 1rem;
    }
    .copy {
      margin-bottom: .5rem;
      br {
        display: none;
      }
    }
    .copyright {
    }
  }
}

@media screen and (max-width: 600px){

  footer {
    .wrapper {
      padding: 2rem 0;
    }
    nav.wrapper {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 2.5rem;
      > a {
        margin-bottom: 2.5rem;
      }
      ul {
        width: 100%;
        flex-wrap: wrap;
        row-gap: 1.5rem;
        li {
          width: 50%;
          margin-left: 0;
          text-align: left;
        }
      }
    }
    div.wrapper {
      text-align: left;
      .copy {
        font-size: .9rem;
        margin-bottom: 1rem;
        br {
          display: inline;
        }
      }
      .copyright {
        font-size: .9rem;
      }
    }
  }
  
}