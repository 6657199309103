// page style

.admin {
  
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  input[type=text],
  input[type=email],
  input[type=password] {
    width: 100%;
    font-size: 1.2rem;
    background-color: $form-bg;
    padding: 1rem;
    display: block;
    margin-bottom: 1rem;
    border: none;
    &::placeholder {
      color: #999;
    }
  }
  
  select {
    background-color: $form-bg;
    border: none;
    padding: 1rem 3rem 1rem 1rem;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    option {
      padding: 1rem;
    }
  }
  .select-wrapper {
    position: relative;
    &::after {
      content: '';
      width: 6px;
      height: 6px;
      display: inline-block;
      margin-left: .8rem;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      transform: rotate(45deg);
      top: 1.2rem;
      right: 1rem;
      position: absolute;
    }
  }
  
  .form-message {
    color: red;
    font-weight: 600;
    font-size: 1.2rem;
  }
  
  &.before-login {
    main {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      width: 100%;
      section {
        text-align: left;
        width: 100%;
        max-width: 400px;
        h2 {
          font-size: 1.5rem;
          font-weight: 300;
          margin-bottom: 3rem;
          line-height: 100%;
        }
        .description {
          font-size: 1.1rem;
          margin-bottom: 2rem;
        }
        .signup-form {
          &:nth-of-type(n+2) {
            margin-top: 3rem;
            border-top: 1px solid $form-border;
            padding-top: 3rem;
          }
          .button {
            width: 100%;
            padding: .8rem;
            font-size: 1.3rem;
            font-weight: 300;
          }
          p {
            font-size: 1rem;
            margin-bottom: 1rem;
          }
        }
        .to-otherform {
          font-size: 1rem;
          text-align: center;
          margin-top: 2rem;
          border-top: 1px solid $form-border;
          padding-top: 2rem;
        }
      }
    }
  }
  
  &.after-login {
    
    .wrapper {
      margin: 0 auto;
      padding: 2rem;
    }

    main {
      display: flex;
      top: 58px;
      height: calc( 100vh - 58px );
      border-top: 1px solid $admin-border;
      position: fixed;
      width: 100vw;
      .mypage-nav {
        padding: 2rem 0;
        text-align: right;
        border-right: 1px solid $admin-border;
        position: sticky;
        left: 0;
        width: 200px;
        ul {
          li {
            a {
              display: block;
              width: 100%;
              padding: 1.2rem 0;
              text-decoration: none;
              position: relative;
              right: -1px;
              span {
                padding: 0 2rem;
                display: block;
                white-space: nowrap;
                border-right: 1px solid transparent;
                font-size: 1.3rem;
                line-height: 100%;
                color: #666;
              }
              &.on,
              &:hover {
                span {
                  font-weight: 600;
                  color: $color-base-orange;
                  border-right-color: $color-base-orange;
                }
              }
            }
          }
        }
      }
      .mypage-body {
        flex-grow: 1;
        background-color: $admin-bg;
        padding: 3rem 2rem;
        overflow: scroll;
        section {
          max-width: 720px;
          margin: 0 auto 2rem;
          text-align: left;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            line-height: 150%;
            font-size: 1.2rem;
            margin-bottom: 1rem;
          }
          h3 {
            font-size: 1.3rem;
            font-weight: 300;
            margin-bottom: 1.5rem;
          }
          .section-body {
            padding: 1.5rem;
            background-color: white;
            border-radius: .5rem;
          }
          &.admin-howtouse {
            ol {
              list-style: none;
              li {
                position: relative;
                h4 {
                  color: #999;
                  font-weight: 600;
                  position: absolute;
                  left: 0;
                }
                div {
                  padding-bottom: 1.5rem;
                  border-bottom: 1px solid $admin-border;
                  margin: 0 0 1.5rem 6rem;
                  p {
                    margin: 0;
                  }
                  > code {
                    margin: 1rem 0 .5rem;
                  }
                }
                &:last-child {
                  div {
                    padding-bottom: 0;
                    border: none;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          &.admin-register-site {
            .submit-website {
              div {
                display: flex;
                justify-content: space-between;
                gap: .5rem;
                input {
                  margin: 0;
                }
                .button {
                  padding: 1rem;
                  font-size: 1.2rem;
                }
                .button-disabled {
                  padding: 1rem;
                  font-size: 1.2rem;
                }
              }
            }
          }
          &.admin-registered-sites {
            .registered-site {
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              margin-bottom: 1rem;
              p {
                margin: 0;
              }
              &.empty p {
                color: #999;
              }
              .button {
                background-color: white;
                color: #999;
                font-weight: normal;
                &:hover {
                  background-color: #F5F5F5;
                  color: red;
                  box-shadow: none;
                }
              }
            }
          }
          &.admin-settings {
            .section-body {
              margin-bottom: 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1rem;
              p {
                margin: 0;
              }
              .site-name {
                flex-grow: 1;
              }
              .button {
                padding: 1rem;
              }
            }
          }
          &.admin-changepassword {
            .section-body {
              .button {
                width: 100%;
                padding: .8rem;
                font-size: 1.3rem;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
  
}

@media screen and (max-width: 768px){


}

@media screen and (max-width: 600px){


}