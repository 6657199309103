header {
  background-color: white;
  .wrapper {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      picture {
        display: flex;
        align-items: center;
        img {
          display: inline-block;
        }
      }
    }
    nav.pc {
      display: flex;
      justify-content: space-between;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          margin-left: 1rem;
          a {
            display: inline-block;
            font-size: 1.2rem;
            padding: .8rem 2rem;
            line-height: 100%;
            text-decoration: none;
            &.button {
              padding: .8rem 2rem;
            }
          }
        }
      }
    }
    input#nav-state {
      display: none;
    }
    nav.mobile {
      display: none;
    }
  }
}

.lp {
  header {
    .wrapper {
      padding: 2rem;
    }
  }
}

.admin.after-login {
  
  header {
    position: fixed;
    width: 100vw;
    z-index: 100;
    .wrapper {
      padding: 1.6rem 2rem;
      max-width: initial;
      h1 {
        picture {
          height: 17px;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
      .header-menu {
        position: relative;
        #menu-state {
          display: none;
          &:checked {
            & ~ .icon-user::after {
              transform: rotate(-135deg);
              top: 2px;
            }
            & ~ ul {
              display: block;
            }
          }
        }
        .icon-user {
          vertical-align: middle;
          cursor: pointer;
          &::before {
            content: '';
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: .5rem;
            background-image: url('../images/icon_user.svg');
            background-size: contain;
            top: 2px;
            position: relative;
          }
        }
        .pulldown {
          padding: .5rem 0;
          &::after {
            content: '';
            width: 6px;
            height: 6px;
            display: inline-block;
            margin-left: .8rem;
            border-right: 1px solid #333;
            border-bottom: 1px solid #333;
            transform: rotate(45deg);
            top: -2px;
            position: relative;
          }
        }
        ul {
          display: none;
          padding: .5rem;
          text-align: right;
          background: white;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
          border-radius: 6px;
          position: absolute;
          top: 2.5rem;
          right: 0;
          li {
            a {
              display: block;
              text-decoration: none;
              text-align: center;
              padding: .5rem 1rem;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px){

  header {
    .wrapper {
      position: relative;
      gap: 1.3rem;
      h1 {
        flex-grow: 1;
        picture {
          display: flex;
          align-items: center;
          width: 129px;
          img {
            display: inline-block;
          }
        }
      }
      nav.pc {
        ul {
          li {
            &:first-child {
              display: none;
            }
            a {
              &.button {
                font-size: 1rem;
                padding: .2rem .5rem;
              }
            }
          }
        }
      }
      #nav-state:checked ~ nav.mobile {
        label {
          background-image: url('../images/lp/menu_on.svg');
        }
        div {
          display: flex;
        }
      }
      nav.mobile {
        display: inherit;
        position: relative;
        label {
          display: inline-block;
          width: 18px;
          height: 13px;
          background-image: url('../images/lp/menu_off.svg');
          background-size: contain;
          background-position: center;
          text-indent: -3000px;
          cursor: pointer;
          vertical-align: middle;
        }
        div {
          display: none;
          position: fixed;
          top: 44px;
          left: 0;
          width: 100vw;
          height: calc( 100vh - 44px );
          background-color: rgba(255,255,255,0.8);
          backdrop-filter: blur(8px);
          align-items: center;
          ul {
            width: 100%;
            li {
              text-align: center;
              &::after {
                content: '';
                width: 3rem;
                height: 1px;
                background-color: #e5e5e5;
                display: block;
                margin: 0 auto;
              }
              &:last-child::after {
                display: none;

              }
              a {
                font-size: 1.5rem;
                line-height: 100%;
                padding: 2rem;
                display: block;
              }
            }
          }
        }
      }
    }
  }
  
  .lp {
    header {
      .wrapper {
        padding: .9rem 1.3rem;
      }
    }
  }

}