// page style

.terms {
  
  main {
    margin-top: 4rem;
    margin-bottom: 4rem;
    .wrapper {
      text-align: left;
      h2 {
        font-weight: normal;
        margin-bottom: 4rem;
      }
      h3 {
        font-weight: normal;
        margin-bottom: 1rem;
      }
      ol {
        margin: 0 0 1rem 3rem;
        li {
          line-height: 150%;
          ol.bracket {
            margin-bottom: 0;
          }
        }
      }
      ol.bracket {
        padding-left: 1rem;
        margin-left: 2rem;
        li {
          list-style-type: none;
          counter-increment: cnt;
          position: relative;
          &::before {
            content: "(" counter(cnt) ")";
            display: inline-block;
            margin-left: -3.5em;
            width: 3em;
            text-align: right;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      p {
        font-size: 1rem;
        margin: 0 0 1rem;
        text-indent: 1rem;
      }
      time {
        display: block;
        margin-top: 2rem;
        padding-left: 1rem;
      }
    }
  }
  
}

@media screen and (max-width: 768px){


}

@media screen and (max-width: 600px){


}