// page style

.lp {

  main {
    
    a {
      color: #333;
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  
    .button {
      color: white;
      &:hover {
        transform: scale(1.05);
      }
    }
    
    section {
  
      h4 {
        font-size: 2.3rem;
        font-weight: normal;
        margin-bottom: 3rem;
      }
  
      .note {
        font-size: 1.2rem;
      }
  
      &.block-hero {
        background-image: url('../images/lp/hero_2x.png');
        background-position: calc(50% + 357px) center;
        background-size: contain;
        margin-bottom: 10rem;
        .wrapper {
          padding: 11rem 4rem;
          > div {
            text-align: left;
            h2 {
              font-size: 3rem;
              margin-bottom: 2.5rem;
            }
            h3 {
              font-size: 1.5rem;
              font-weight: normal;
              margin-bottom: 2.5rem;
            }
          }
        }
      }
      &.block-desc1,
      &.block-desc2,
      &.block-desc3 {
        margin: 12rem 0;
        .wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          picture {
            margin: 0 auto;
          }
          > div {
            width: 50%;
            text-align: left;
          }
        }
      }
      &.block-desc1 {
        .wrapper {
          picture {
            height: 347px;
            img {
              display: block;
              width: auto;
              height: 100%;
              filter: drop-shadow(6px 6px 14px rgba(0, 0, 0, 0.15));
            }
          }
        }
      }
      &.block-desc2 {
        .wrapper {
          picture {
            order: 2;
            height: 311px;
            margin: 0 0 0 auto;
            img {
              display: block;
              width: auto;
              height: 100%;
              filter: drop-shadow(6px 6px 14px rgba(0, 0, 0, 0.15));
            }
          }
          > div {
            .note {
              margin-top: 1rem;
            }
          }
        }
      }
      &.block-desc3 {
        .wrapper {
          flex-direction: column;
          .note {
            text-align: center;
            margin-bottom: 4rem;
          }
          .logo-parade {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 8%;
            row-gap: 4rem;
            align-items: center;
            li {
              //max-width: calc(16.6% - 2rem);
              max-height: 60px;
              picture {
                display: block;
                width: auto;
                height: 100%;
                max-height: 60px;
                img {
                  display: block;
                  //max-width: 110px;
                  width: auto;
                  height: 100%;
                  max-height: 60px;
                  &.square {
                  }
                }
              }
            }
          }
        }
      }
      &.block-cta {
        background-image: url('../images/lp/cta_bg_2x.png');
        background-size: cover;
        background-position: center;
        .wrapper {
          padding: 10rem 0;
          h4 {
            color: white;
            font-weight: bold;
            margin-bottom: 4rem;
          }
          p {
            font-size: 1.5rem;
            color: white;
            margin-bottom: 4rem;
          }
  
        }
      }
      &.block-news {
        margin: 10rem 0;
        .wrapper {
          text-align: left;
          ul {
            display: flex;
            justify-content: space-between;
            gap: 2%;
            li {
              width: 25%;
              min-height: 10rem;
              background-color: white;
              box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
              padding: 1.5rem;
              h5 {
                color: $color-base-orange;
                font-size: 1rem;
                font-weight: bold;
                margin-bottom: 1rem;
              }
              p {
                font-size: 1rem;
                line-height: 150%;
                margin-bottom: .5rem;
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  text-decoration: underline;
                  &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    display: inline-block;
                    border-top: 1px solid $color-base-orange;
                    border-right: 1px solid $color-base-orange;
                    top: -2px;
                    position: relative;
                    transform: rotate(45deg);
                    margin-right: .5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    
    main {

      section {

        h4 {
          font-size: 1.7rem;
          margin-bottom: 2rem;
        }

        p {
          font-size: 1.2rem;
        }

        &.block-hero {
          background-position: calc(50% + 240px) center;
          .wrapper {
            padding: 8rem 4rem;
            > div {
              h2 {
                font-size: 2.3rem;
              }
              h3 {
                font-size: 1.2rem;
              }
            }
          }
        }

        &.block-desc2 {
          .wrapper {
            picture {
              height: 250px;
            }
          }
        }

        &.block-cta {
          .wrapper {
            padding: 8rem 0;
          }
        }

      }
      
    }


  }

  @media screen and (max-width: 600px) {
    
    main {

      .wrapper {
        padding: 0 2rem;
      }

      section {

        h4 {
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
        }

        p {
          font-size: 1.1rem;
        }

        .note {
          font-size: 1rem;
        }

        .button {
          &.button-l {
            width: 100%;
            font-size: 1.5rem;
            border-radius: .5rem;
            padding: .8rem;
          }
        }

        &.block-hero {
          padding-bottom: 22rem;
          background-position: center bottom;
          .wrapper {
            padding: 5rem 1.3rem;
            > div {
              text-align: center;
              h2 {
                display: inline-block;
                font-size: 2.4rem;
                padding-left: 2rem;
                text-align: left;
              }
              h3 {
                display: block;
                font-size: 1.2rem;
                text-align: center;
              }
              .button {
                display: inline-block;
                width: auto;
                padding: .8rem 2rem;
              }
            }
          }
        }

        &.block-desc1 {
          .wrapper {
            flex-direction: column;
            gap: 6rem;
            picture {
              height: 258px;
            }
            > div {
              width: 100%;

            }
          }
        }

        &.block-desc2 {
          .wrapper {
            flex-direction: column;
            gap: 6rem;
            picture {
              order: 1;
              height: 208px;
              margin: 0 auto;
            }
            > div {
              width: 100%;
              order: 2;
            }
          }
        }

        &.block-desc3 {
          .wrapper {
            align-items: flex-start;
            h4 {
              text-align: left;
            }
            .note {
              text-align: left;
              li {
                padding-left: 1.1rem;
                text-indent: -1.1rem;
              }
            }
            .logo-parade {
              gap: 12%;
              row-gap: 1.5rem;
              li {
                max-height: calc(33% - 2rem);
                picture {
                  img.square {
                    width: 60px;
                  }
                }
              }
            }
          }
        }

        &.block-cta {
          .wrapper {
            padding: 6rem 2rem;
            h4 {
              font-size: 1.8rem;
            }
            p {
              font-size: 1.1rem;
            }
          }
        }

        &.block-news {
          margin: 6rem 0;
          .wrapper {
            h4 {
              margin-bottom: 4rem;
            }
            ul {
              flex-direction: column;
              gap: 2rem;
              li {
                width: 100%;
                min-height: auto;
              }
            }
          }
        }

      }
      
    }

  }
  
}